/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingTerms
 * path: /onboarding/terms
 *
 * this is a terms confirmation page to start onboarding
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/theme/Header';
import BottomLinks from '../../components/theme/BottomLinks';
import Checkbox from '../../components/forms/Checkbox';
import ButtonLink from '../../components/buttons/ButtonLink';
import Button from '../../components/buttons/Button';
import PopupTerms from '../../components/popups/PopupTerms';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import '../../styles/terms.scss';

const TermsPage = observer(({ UserStore }) => {
    //state variables
    const [isChecked, setIsChecked] = useState(true);
    const [termsChecked, setTermsChecked] = useState(false);
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [infoChecked, setInfoChecked] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);

    //ref variable for popup
    const popupTermsRef = useRef();

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //function that handles a 'Next' button click
    const next = () => {
        const isValidated = termsChecked && privacyChecked && infoChecked;
        if (!isValidated) {
            setIsChecked(isValidated);
            window.scrollTo({ behavior: 'smooth', top: 200 });
            return null;
        }

        //set terms agree date
        UserStore.setTermsAgreeDate();
        navigate(pageUrl.onboardingContractList);
        return true;
    };

    return (
        <div className="app terms-page">
            <Header isBack />
            <div className="content">
                <div className="wrapper-big">
                    <h1>{trans.t('termsPage', 'h1')}</h1>
                    <div className="item">
                        <div className="item-number">01</div>
                        <div className="item-content">
                            <div className="item-title">{trans.t('termsPage', 'title1')}</div>
                            <div className="item-text">{trans.t('termsPage', 'text1')}</div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-number">02</div>
                        <div className="item-content">
                            <div className="item-title">{trans.t('termsPage', 'title2')}</div>
                            <div className="item-text">{trans.t('termsPage', 'text2')}</div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-number">03</div>
                        <div className="item-content">
                            <div className="item-title">{trans.t('termsPage', 'title3')}</div>
                            <div className="item-text">{trans.t('termsPage', 'text3')}</div>
                        </div>
                    </div>
                </div>
                <div className="conditions">
                    <div className="conditions-container">
                        <div className="boxitem">
                            <Checkbox role="checkboxTerms" onClick={(val) => setTermsChecked(val)} className={!termsChecked && !isChecked ? 'red' : 'checkbox-white'}>
                                <div className="check-text">
                                    {trans.t('termsPage', 'checkboxText1')}&nbsp;
                                    <ButtonLink
                                        text={trans.t('termsPage', 'checkboxLink1')}
                                        onClick={() => popupTermsRef.current.show()}
                                        className={!termsChecked && !isChecked ? 'red' : ''}
                                        isInline
                                    />
                                    *
                                </div>
                            </Checkbox>
                        </div>
                        <div className="boxitem">
                            <Checkbox role="checkboxPrivacy" onClick={(val) => setPrivacyChecked(val)} className={!privacyChecked && !isChecked ? 'red' : 'checkbox-white'}>
                                <div className="check-text">
                                    {trans.t('termsPage', 'checkboxText2')}&nbsp;
                                    <ButtonLink
                                        text={trans.t('termsPage', 'checkboxLink2')}
                                        onClick={() => popupTermsRef.current.show('privacy')}
                                        className={!privacyChecked && !isChecked ? 'red' : ''}
                                        isInline
                                    />
                                    *
                                </div>
                            </Checkbox>
                        </div>
                        <div className="boxitem">
                            <Checkbox role="checkboxInfo" onClick={(val) => setInfoChecked(val)} className={!infoChecked && !isChecked ? 'red' : 'checkbox-white'}>
                                <div className="check-text">
                                    {trans.t('termsPage', 'checkboxText2')}&nbsp;
                                    <ButtonLink
                                        text={trans.t('termsPage', 'checkboxLink3')}
                                        onClick={() => popupTermsRef.current.show('info')}
                                        className={!infoChecked && !isChecked ? 'red' : ''}
                                        isInline
                                    />
                                    *
                                </div>
                            </Checkbox>
                        </div>
                    </div>
                    <Button role="btnNext" text={trans.t('termsPage', 'btnNext')} onClick={next} isRightIcon />
                </div>
            </div>
            <BottomLinks />
            <PopupTerms ref={popupTermsRef} />
        </div>
    );
});

export default TermsPage;
